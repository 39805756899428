import { Application } from "@hotwired/stimulus"
import "htmx.org"

// cars
import Filter from "../../../cars/static_src/cars/components/Filter"
import Form from "../../../cars/static_src/cars/components/Form"
import InsuranceCreator from "../../../cars/static_src/cars/components/InsuranceCreator"
import InsuranceSelecter from "../../../cars/static_src/cars/components/InsuranceSelecter"
import ReplacementChoice from "../../../cars/static_src/cars/components/ReplacementChoice"
import ReplacementDate from "../../../cars/static_src/cars/components/ReplacementDate"
import ReplacementVehicule from "../../../cars/static_src/cars/components/ReplacementVehicule"
import ReplacementVehiculeGroup from "../../../cars/static_src/cars/components/ReplacementVehiculeGroup"

// ui
import AutoScroll from "./components/AutoScroll"
import Theme from "./components/Theme"

// users
import Input from "../../../users/static_src/users/components/Input.js"

const app = Application.start()
app.register("auto-scroll", AutoScroll)
app.register("filter", Filter)
app.register("replacement-vehicule", ReplacementVehicule)
app.register("replacement-vehicule-group", ReplacementVehiculeGroup)
app.register("replacement-choice", ReplacementChoice)
app.register("replacement-date", ReplacementDate)
app.register("form", Form)
app.register("insurance-creator", InsuranceCreator)
app.register("insurance-selecter", InsuranceSelecter)
app.register("theme", Theme)
app.register("input", Input)
