// --- Server API ---

export const patchCompany = async(options, body) => {
  return await api("PATCH", `/api/companies/${body.id}/`, options, body)
}

export const patchUser = async(options, body) => {
  return await api("PATCH", `/api/users/${body.id}/`, options, body)
}

// --- Internals ---

const api = async(method, path, options, body = null) => {
  body = body || {}
  let data
  // eslint-disable-next-line compat/compat
  const response = await fetch(path, {
    method: method || "GET",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": options?.csrfToken || window.CSRF_TOKEN,
    },
    body: method === "GET" ? undefined : JSON.stringify(body),
  })
  if (response.ok && !path.includes("format=")) {
    try {
      data = await response.json()
    } catch (e) {
      // No-op
    }
  }
  return { response, data }
}
