import { Controller } from "@hotwired/stimulus"

import { patchCompany, patchUser } from "../services/api.js"

export default class Input extends Controller {
  static values = { company: Number, csrf: String, user: Number }

  update(event) {
    if (this.companyValue) {
      patchCompany({ csrfToken: this.csrfValue }, { [event.target.id]: event.target.value, id: this.companyValue })
    } else {
      patchUser({ csrfToken: this.csrfValue }, { [event.target.id]: event.target.value, id: this.userValue })
    }
  }
}
